import { BASE_URL } from "./api/config";
// Register the service worker and handle push notification subscription
// Register the service worker and handle push notification subscription
export async function register(config) {
  console.log("here in first");

  // Check if the endpoint exists in localStorage
  const existingEndpoint = localStorage.getItem("push_subscription_endpoint");
  if (existingEndpoint) {
    console.log("Already subscribed. Endpoint:", existingEndpoint);
    return; // Skip the subscription process if endpoint already exists
  }

  if ("serviceWorker" in navigator) {
    console.log("here in service");
    const swUrl = `/service-worker.js`;
    console.log("trying");
    try {
      const registration = await navigator.serviceWorker.register(swUrl);
      console.log("Service Worker registered with scope:", registration.scope);

      // Request permission and subscribe (if granted)
      const subscription = await subscribeToPushNotifications(registration);

      if (subscription) {
        // Save subscription to backend (if successful)
        const success = await saveSubscriptionToServer(subscription);

        if (success) {
          // Save subscription endpoint in localStorage
          const endpoint = subscription.endpoint;
          localStorage.setItem("push_subscription_endpoint", endpoint);
          console.log("Subscription endpoint saved to localStorage:", endpoint);

          if (config && config.onSubscription) {
            config.onSubscription(subscription);
          }
          if (config && config.onComplete) {
            config.onComplete();
          }
        }
      }
    } catch (error) {
      console.error(
        "Error during service worker registration or subscription:",
        error
      );
    }
  }
}

// Event listener to trigger the register function when the page is fully loaded
window.onload = function () {
  const config = {
    onSubscription: (subscription) => {
      console.log("Subscription received:", subscription);
    },
    onComplete: () => {
      console.log("Subscription process complete.");
    },
  };

  register(config);
};

async function subscribeToPushNotifications(registration) {
  try {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.error("Push notifications permission denied.");
      return null;
    }

    const key = process.env.REACT_APP_VAPID_PUBLIC_KEY;
    if (!key) {
      console.error("VAPID public key is missing.");
      return null;
    }
    if (!key || !key.match(/^[A-Za-z0-9-_]+$/)) {
      console.error("Invalid VAPID public key format.");
      return null;
    }

    const applicationServerKey = urlBase64ToUint8Array(key);
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey,
    });

    console.log("Push subscription:", subscription);
    return subscription;
  } catch (error) {
    console.error("Error during push subscription:", error);
    return null;
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

export async function saveSubscriptionToServer(subscription) {
  try {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      console.error("No user found in localStorage.");
      return false;
    }

    const response = await fetch(`${BASE_URL}/notification/save-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        subscription,
        userId,
      }),
    });

    if (!response.ok) {
      throw new Error(
        `Failed to save subscription: ${response.status} ${response.statusText}`
      );
    }

    console.log("Subscription saved successfully.");
    // Optionally store a minimal version of the subscription in localStorage (e.g., endpoint)
    localStorage.setItem("push_subscription_endpoint", subscription.endpoint);
    return true;
  } catch (error) {
    console.error("Failed to save subscription to server:", error);
    return false;
  }
}
