import React, { useState, useEffect } from "react";
import {
  register,
  saveSubscriptionToServer,
} from "../serviceWorkerRegistration";
import { sendPushNotification } from "../api/webPush";

export const sendNotification = async (userId, role, parentId, payload) => {
  try {
    const res = await sendPushNotification(userId, role, parentId, payload);
    if (res) {
      console.log(res);
    }
  } catch (error) {
    console.log(error);
  }
};

const PushNotificationButton = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // Control popup visibility

  useEffect(() => {
    // Check subscription status when the app is loaded
    checkSubscriptionStatus();
  }, []);

  const checkSubscriptionStatus = async () => {
    const savedSubscription = localStorage.getItem("push_subscription");
    if (savedSubscription) {
      setIsSubscribed(true);
    } else {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      setIsSubscribed(subscription !== null);
    }

    // If not subscribed, show the popup
    if (!isSubscribed) {
      setShowPopup(true);
    }
  };

  const handleSubscribeClick = async () => {
    if (isSubscribed) {
      console.log("Already subscribed.");
      return;
    }

    // Trigger service worker registration and subscription
    register({
      onSubscription: (subscription) => {
        saveSubscriptionToServer(subscription);
        localStorage.setItem("push_subscription", JSON.stringify(subscription)); // Store subscription in localStorage
      },
      onComplete: () => {
        setIsSubscribed(true); // Update the UI once subscribed
        setShowPopup(false); // Close the popup once subscribed
      },
    });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="flex flex-col items-center mt-4">
      {showPopup && !isSubscribed && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80 text-center">
            <h2 className="text-lg font-semibold mb-4">
              Would you like to subscribe to push notifications?
            </h2>
            <button
              onClick={handleSubscribeClick}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Subscribe
            </button>
            <button
              onClick={closePopup}
              className="text-gray-500 mt-4 hover:underline"
            >
              No, thanks
            </button>
          </div>
        </div>
      )}

      <button
        onClick={() =>
          sendNotification(
            {
              title: "Your Notification Title",
              body: "Your notification body goes here",
              icon: "/favicon.png",
              data: {
                customData:
                  "Any additional data you want to send with the notification",
              },
            },
            null,
            "marketer"
          )
        }
        className="bg-green-500 text-white px-4 py-2 rounded mt-4 hover:bg-green-600"
      >
        Send Push Notification
      </button>
    </div>
  );
};

export default PushNotificationButton;
