import { BASE_URL } from "./config";

export const sendPushNotification = async (userId, role, parentId, payload) => {
  try {
    const body = JSON.stringify({
      userId,
      role,
      parentId,
      payload,
    });
    const headers = {
      Authorization: `Bearer YOUR_ACCESS_TOKEN`, // Replace with the actual access token for WNS
      "Content-Type": "application/json",
      "X-WNS-Type": "wns/raw", // Use "wns/raw" for raw notifications
    };
    const response = await fetch(
      `${BASE_URL}/notification/send-create-push-notification-to-user`,
      {
        method: "POST",
        headers: headers,
        body: body,
      }
    );

    if (response.ok) {
      console.log("Notification successfully sent.");
    } else {
      console.error("Failed to send notification.");
    }
  } catch (error) {
    console.error("Error sending notification:", error);
  }
};
export const sendUpdatePushNotification = async (
  payload,
  userId,
  role,
  agreementId,
  parentId
) => {
  try {
    const body = JSON.stringify({
      payload,
      userId,
      role,
      agreementId,
      parentId,
    });
    console.log(body);
    const headers = {
      Authorization: `Bearer YOUR_ACCESS_TOKEN`, // Replace with the actual access token for WNS
      "Content-Type": "application/json",
      "X-WNS-Type": "wns/raw", // Use "wns/raw" for raw notifications
    };
    const response = await fetch(
      `${BASE_URL}/notification/send-update-push-notification-to-user`,
      {
        method: "POST",
        headers: headers,
        body: body,
      }
    );

    if (response.ok) {
      console.log("Notification successfully sent.");
    } else {
      console.error("Failed to send notification.");
    }
  } catch (error) {
    console.error("Error sending notification:", error);
  }
};
export const sendDeletePushNotification = async (
  payload,
  userId,
  agreementId
) => {
  try {
    const body = JSON.stringify({
      payload,
      userId,
      agreementId,
    });
    console.log(body);
    const headers = {
      Authorization: `Bearer YOUR_ACCESS_TOKEN`, // Replace with the actual access token for WNS
      "Content-Type": "application/json",
      "X-WNS-Type": "wns/raw", // Use "wns/raw" for raw notifications
    };
    const response = await fetch(
      `${BASE_URL}/notification/send-delete-push-notification-to-user`,
      {
        method: "POST",
        headers: headers,
        body: body,
      }
    );

    if (response.ok) {
      console.log("Notification successfully sent.");
    } else {
      console.error("Failed to send notification.");
    }
  } catch (error) {
    console.error("Error sending notification:", error);
  }
};
export const clearUserNotifications = async (userId) => {
  try {
    console.log("called");
    // Construct the request payload
    const body = JSON.stringify({
      userId, // User ID to identify the user
      notifications: {
        endpoint: "",
        keys: {
          p256dh: "",
          auth: "",
        },
      },
    });

    // Set the headers for the request
    const headers = {
      Authorization: `Bearer YOUR_ACCESS_TOKEN`, // Replace with the actual access token for authentication
      "Content-Type": "application/json",
    };

    // Send the request to the backend endpoint
    const response = await fetch(
      `${BASE_URL}/notification/clear-user-notifications`,
      {
        method: "POST",
        headers: headers,
        body: body,
      }
    );

    if (response.ok) {
      console.log("User notifications cleared successfully.");
    } else {
      console.error("Failed to clear user notifications.");
    }
  } catch (error) {
    console.error("Error clearing user notifications:", error);
  }
};
